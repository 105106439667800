<template>
  <el-dialog
    title="自定义快捷功能"
    :visible="visible"
    :close-on-click-modal="false"
    width="1100px"
    @close="close"
  >
    <el-tree
      ref="tree"
      :data="treeData"
      node-key="value"
      :default-expand-all="true"
      :show-checkbox="true"
    >
      <span slot-scope="{ node, data }">
        <i v-if="data.Children" class="el-icon-folder" />
        <i v-else class="el-icon-document" />
        {{ node.label }}
      </span>
    </el-tree>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="save">提交</el-button>
      <el-button @click="close">关闭</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { submit } from "@/api/base";

export default {
  name: "QuickLinkEdit",
  data() {
    return {
      visible: false,
      type: "",
      title: "",
      form: {},
      treeData: [],
    };
  },
  methods: {
    init() {
      this.type = "自定义快捷功能";
      this.title = "自定义快捷功能";
      this.visible = true;
      this.$nextTick(() => {
        this.fetchData();
      });
    },
    async fetchData() {
      const { data } = await submit("/api/home/getQuickLinkData", this.param);
      this.treeData = data.AllAuthList;
      this.$nextTick(() => {
        const checkedKeys = [];
        (data.AuthList || []).forEach((key) => {
          const node = this.$refs.tree.getNode(key);
          if (node && node.isLeaf) {
            checkedKeys.push(key);
          }
        });
        this.$refs.tree.setCheckedKeys(checkedKeys);
      });
    },
    async save() {
      const param = [];
      let checkKeys = this.$refs.tree.getCheckedKeys();
      if (!checkKeys || checkKeys.length <= 0) {
        this.$baseMessage("请选择要保存的快捷功能", "error");
        return;
      }
      checkKeys.forEach((item) => {
        const node = this.$refs.tree.getNode(item);
        if (node && node.isLeaf) {
          param.push({ label: node.label, value: item });
        }
      });
      if (param.length > 9) {
        this.$baseMessage("最多只能选择9个快捷功能", "error");
        return;
      }
      await submit("/api/home/saveQuickLink", { Data: param });
      this.$baseMessage("保存成功", "success");
      this.$emit("getHomeData");
      this.close();
    },
    close() {
      this.treeData = [];
      this.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.editForm {
  border: 1px solid #ebeef5;
  padding-top: 10px;
  margin-bottom: 10px;
}

.operation {
  border: 1px solid #ebeef5;
  padding: 5px;
  margin-bottom: 10px;

  > div {
    display: inline-block;
    margin: 0 10px;
  }
}
</style>
