<template>
  <div class="home">
    <div>
      <div>
        <div class="entrance">
          <div>
            <el-card>
              <div class="entrance-xiebutou">
                <img class="xiebutou" :src="require('@/assets/img/home/xiebutou.png')" />
                <router-link to="home">
                  <el-button type="primary">前往采购</el-button>
                </router-link>
              </div>
              <router-link v-for="entrance in entrances" :key="entrance.enTitle" :to="entrance.routeName">
                <div class="entrance-item" :style="{ background: entrance.backgroundColor }">
                  <div class="entrance-item-en-title" v-html="entrance.enTitle"></div>
                  <img :src="entrance.src" />
                  <div class="entrance-item-title">{{ entrance.title }}</div>
                  <div class="entrance-item-desc">{{ entrance.desc }}</div>
                </div>
              </router-link>
            </el-card>
          </div>
          <div>
            <div class="order-count">
              <el-card>
                <div class="card-title">
                  <i class="el-icon-s-grid" />
                  <span>订单销售</span>
                </div>
                <el-divider />
                <span class="count-item">
                  <div>{{ (data.OrderRecent || {}).WaitAuditOrderNum }}</div>
                  <div>待审核</div>
                </span>
                <span class="count-item">
                  <div>{{ (data.OrderRecent || {}).SendingOrderNum }}</div>
                  <div>待发货</div>
                </span>
                <span class="count-item">
                  <div>{{ (data.OrderRecent || {}).TodaySendedOrderNum }}</div>
                  <div>今日发货</div>
                </span>
                <span class="count-item">
                  <div>{{ (data.OrderRecent || {}).WaitSendingOrderNum }}</div>
                  <div>未发货</div>
                </span>
              </el-card>
            </div>
            <div class="stall-count">
              <el-card>
                <div class="card-title">
                  <i class="el-icon-s-grid" />
                  <span>报单销售</span>
                </div>
                <el-divider />
                <span class="count-item">
                  <div>
                    {{ (data.StallOrderRecent || {}).WaitGoodsOrderNum }}
                  </div>
                  <div>待配货</div>
                </span>
                <span class="count-item">
                  <div>{{ (data.StallOrderRecent || {}).SaledOrderNum }}</div>
                  <div>已出售</div>
                </span>
                <span class="count-item">
                  <div>{{ (data.StallOrderRecent || {}).NoGoodsOrderNum }}</div>
                  <div>缺货统计</div>
                </span>
              </el-card>
            </div>
          </div>
        </div>
        <div class="fast-link">
          <el-card>
            <div class="card-title">
              <i class="el-icon-s-grid" />
              <span>快捷功能</span>
              <span style="float: right; font-size: 12px; cursor: pointer" @click="quickLinkEdit()">自定义快捷功能</span>
            </div>
            <el-divider />
            <div v-for="link in data.QuickLinkList || []" :key="link.label" @click="goRoute(link.value)"
              class="fast-link-item">
              <img :src="link.src" />
              <span>{{ link.label }}</span>
            </div>
          </el-card>
        </div>
      </div>
      <div class="sale-trend">
        <el-card>
          <div class="card-title">
            <i class="el-icon-s-marketing" />
            <span>销售走势（近7天）</span>
          </div>
          <el-divider />
          <div ref="chart" style="width: 1300px; height: 350px; margin-top: 20px"></div>
        </el-card>
      </div>
    </div>
    <div>
      <el-card class="notice">
        <div class="card-title">
          <i class="el-icon-s-grid" />
          <span>系统公告</span>
        </div>
        <el-divider />
        <ul class="notice-content">
          <li v-for="item in (data.ManageNoticeList || {}).datas || []" :key="item.Title"
            @click="goRoute('sysAnnouncement')">
            <span class="notice-title">{{ item.Title }}</span>
            <span class="notice-time">{{ item.CreateTime }}</span>
          </li>
        </ul>
      </el-card>
      <div v-for="(advert, index) in data.AdvertList || []" :key="advert.Title + index" class="ads">
        <a v-if="advert.Type == 'guide'" @click="showGuide(advert.Title, advert.Content)">
          <img :src="advert.Image" :title="advert.Title" />
        </a>
        <a v-else :href="advert.Url" target="_blank">
          <img :src="advert.Image" :title="advert.Title" />
        </a>
      </div>
    </div>
    <div class="page-dialog">
      <quick-link-edit v-if="quickLinkDialogVisible" ref="quickLinkEdit" @getHomeData="getHomeData"></quick-link-edit>
    </div>
    <el-dialog title="欢迎使用云果仓！" :visible="guideParentVisible" :close-on-click-modal="false" width="700px"
      @close="guideParentVisible = false">
      <el-dialog :title="guideTitle" :visible="guideVisible" :close-on-click-modal="false" width="900px" append-to-body
        @close="guideVisible = false">
        <div class="guide-container">
          <el-tabs v-model="guideActiveName" tab-position="left">
            <el-tab-pane v-for="(guide, index) in guides || []" :key="guide.title + index" :name="guide.title"
              :label="guide.title">
              <el-image style="width: 650px; height: 390px" :src="guide.img" :title="guide.title" fit="fill"
                :preview-src-list="[guide.img]"> </el-image>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="guideVisible = false"> 上一步 </el-button>
          <el-button type="primary" @click="
                                                      {
            guideVisible = false;
            guideParentVisible = false;
          }
            ">
            已了解，开始使用
          </el-button>
        </div>
      </el-dialog>
      <div>
        <div id="newbie-guide-top">
          <div>新手教程</div>
          <div>体验云果仓核心功能，1分钟快速上手！</div>
        </div>
        <div id="newbie-guide">
          <div @click="getGuide('鞋捕头商品导入已上线，欢迎体验！')" class="newbie-guide-item">
            <div class="newbie-guide-item-top">如何导入鞋捕头商品</div>
            <div>多平台、多店铺、快速下载商品</div>
            <div class="newbie-guide-item-bottom">鞋捕头店铺绑定>选择店铺>导入商品</div>
          </div>
          <div @click="getGuide('档口开单配货已上线，欢迎体验！')" class="newbie-guide-item">
            <div class="newbie-guide-item-top">如何开单配货</div>
            <div>QQ解析、打印配货单、缺货登记</div>
            <div class="newbie-guide-item-bottom">QQ报单复制>解析开单>打单配货>缺货登记>出售成功</div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="guideParentVisible = false"> 暂不选择，立即使用 </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { submit } from '@/api/base';
import QuickLinkEdit from './components/QuickLinkEdit.vue';

export default {
  name: 'home',
  components: {
    QuickLinkEdit,
  },
  data() {
    return {
      quickLinkDialogVisible: false,
      data: {},
      entrances: [
        {
          backgroundColor: '#F5F9FC',
          enTitle: 'Product Management',
          src: require('@/assets/img/home/product.png'),
          title: '商品管理',
          desc: '智能化实时管理',
          routeName: 'home',
        },
        {
          backgroundColor: '#F7F6FA',
          enTitle: 'Customer Management',
          src: require('@/assets/img/home/customer.png'),
          title: '客户管理',
          desc: '多维度综合分析',
          routeName: 'home',
        },
        {
          backgroundColor: '#FAF7F6',
          enTitle: 'Customer<br/>Sales List',
          src: require('@/assets/img/home/sale.png'),
          title: '客户销量榜单',
          desc: '精准统计排行',
          routeName: 'home',
        },
      ],
      guideParentVisible: false,
      guideVisible: false,
      guideActiveName: '',
      guideTitle: '',
      guides: [],
    };
  },
  activated() {
    this.removeStyle();
  },
  deactivated() {
    this.recoverStyle();
  },
  mounted() {
    this.$nextTick(() => {
      this.getHomeData();
    });
  },
  methods: {
    removeStyle() {
      document.getElementById('main-container').setAttribute('style', 'background:rgba(233, 237, 241, 1);');
      //document.querySelector('.breadcrumb').setAttribute('style', 'display:none;');
    },
    recoverStyle() {
      document.getElementById('main-container').setAttribute('style', 'background:white;');
      //document.querySelector('.breadcrumb').setAttribute('style', 'display:block;');
    },
    async getHomeData() {
      const { data } = await submit('/api/home/getData', {});
      this.data = data;
      ((this.data.ManageNoticeList || {}).datas || []).forEach((item) => {
        item.CreateTime = item.CreateTime.substring(0, item.CreateTime.indexOf(' '));
      });
      data.QuickLinkList.forEach((item) => {
        item.src = require('@/assets/img/home/fast.png');
      });
      this.setEchartData();
      if (data.IsNewbie) {
        this.guideParentVisible = true;
      }
    },
    setEchartData() {
      const chart = this.$refs.chart;
      if (chart) {
        let myChart = this.$echarts.init(chart);
        const option = {
          legend: { data: [] },
          xAxis: {},
          yAxis: {},
          series: [],
        };
        option.series = [...this.data.SaleFlow.order_series, ...this.data.SaleFlow.stall_order_series];
        option.series.map((item) => {
          option.legend.data.push(item.name);
        });
        option.xAxis.data = this.data.SaleFlow.legend;
        myChart.setOption(option);
        window.addEventListener('resize', function () {
          myChart.resize();
        });
        this.$on('hook:destroyed', () => {
          window.removeEventListener('resize', function () {
            myChart.resize();
          });
        });
      }
    },
    goRoute(routeName) {
      this.$router.push({ name: routeName });
    },
    quickLinkEdit() {
      this.quickLinkDialogVisible = true;
      this.$nextTick(() => {
        this.$refs.quickLinkEdit.init();
      });
    },
    getGuide(title) {
      const guideArr = (this.data.AdvertList || []).filter((item) => {
        return item.Type == 'guide';
      });
      if (!guideArr || guideArr.length == 0) {
        return;
      }
      for (let guide of guideArr) {
        if (guide.Title == title) {
          this.showGuide(guide.Title, guide.Content);
          break;
        }
      }
    },
    showGuide(title, content) {
      this.guideTitle = title;
      if (!content) {
        return;
      }
      try {
        this.guides = JSON.parse(content);
        if (this.guides && this.guides.length > 0) {
          this.guideActiveName = this.guides[0].title;
        }
        this.guideParentVisible = true;
        this.$nextTick(() => {
          this.guideVisible = true;
        });
      } catch {
        this.guides = [];
        this.guideParentVisible = false;
        this.guideVisible = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  width: 1750px;

  .el-card {
    margin: 0px 10px 10px 0px;
  }

  .card-title {
    height: 30px;
    font-size: 15px;
    font-weight: 500;

    i {
      margin-right: 3px;
      color: #3975df;
    }
  }

  >div {
    display: inline-block;
    vertical-align: top;

    .entrance {
      display: inline-block;

      .el-card {
        width: 900px;
        height: 288px;

        .entrance-xiebutou {
          position: relative;
          width: 430px;
          height: 250px;
          display: inline-block;

          .xiebutou {
            width: 400px;
            height: 288px;
            object-fit: cover;
            position: relative;
            top: -20px;
            left: -20px;
          }

          .el-button {
            position: absolute;
            top: 210px;
            left: 0;
            height: 40px;
            width: 370px;
            font-size: 16px;
            border-radius: 20px;
          }
        }

        .entrance-item {
          display: inline-block;
          margin-left: 20px;
          vertical-align: top;
          height: 250px;
          width: 118px;
          line-height: 22px;
          text-align: center;
          color: #78838e;

          .entrance-item-en-title {
            margin-top: 20px;
            font-size: 14px;
            font-weight: 500;
            color: #b3b8bc;
          }

          img {
            height: 90px;
            width: 90px;
            object-fit: contain;
            display: block;
            border: 1px #ccc dashed;
            margin: 10px auto;
          }

          .entrance-item-title {
            font-size: 14px;
            font-weight: 500;
            color: #333333;
          }

          .entrance-item-desc {
            font-size: 12px;
            font-weight: 400;
            color: #78838e;
          }
        }
      }
    }

    .order-count {
      display: inline-block;

      .el-card {
        width: 480px;
        height: 200px;
      }

      .count-item {
        width: 110px;
      }
    }

    .stall-count {
      display: inline-block;

      .el-card {
        width: 410px;
        height: 200px;
      }

      .count-item {
        width: 123px;
      }
    }

    .count-item {
      display: inline-block;
      height: 149px;
      cursor: pointer;

      text-align: center;

      :first-child {
        margin-top: 36px;
        font-size: 26px;
        font-weight: 600;
      }

      :last-child {
        font-size: 14px;
        font-weight: 400;
      }

      &:hover {
        color: #0075e3;
        box-shadow: 0px 0px 6px 0px rgba(180, 189, 198, 0.8);
      }
    }

    .fast-link {
      display: inline-block;

      .el-card {
        width: 400px;
        height: 500px;
      }

      .fast-link-item {
        color: black;
        height: 120px;
        width: 120px;
        display: inline-block;
        text-align: center;
        margin: 12px 0;
        cursor: pointer;

        &:hover {
          color: #409eff;
          box-shadow: 0px 0px 6px 0px rgba(180, 189, 198, 0.8);
          border-radius: 4px;
        }

        img {
          height: 86px;
          width: 86px;
          object-fit: contain;
          display: block;
          padding: 5px 0 0 15px;
        }
      }
    }

    .sale-trend {
      .el-card {
        width: 1314px;
        height: 404px;
      }
    }

    .notice {
      width: 412px;
      height: 288px;

      .notice-content {
        list-style: none;
        padding: 0;

        li {
          height: 30px;
          line-height: 30px;
          border-bottom: 1px dashed #dfe2e6;
          cursor: pointer;

          &:hover {
            color: #004cbd;
          }

          span {
            display: inline-block;
          }

          .notice-title {
            width: 270px;
          }

          .notice-time {
            width: 75px;
            margin-left: 20px;
          }
        }
      }
    }

    .ads {
      margin-bottom: 10px;

      img {
        width: 412px;
        height: 198px;
        object-fit: cover;
        border-radius: 4px;
      }
    }
  }

  #newbie-guide-top {
    >div:first-child {
      font-size: 25px;
      font-weight: 600;
      margin-bottom: 10px;
    }
  }

  #newbie-guide {
    margin-top: 10px;

    .newbie-guide-item {
      display: inline-block;
      width: 320px;
      height: 100px;
      cursor: pointer;
      box-sizing: border-box;
      margin-right: 20px;
      border: 1px solid #0075e3;
      border-radius: 5px;
      padding: 10px;
      text-align: center;
      font-size: 12px;

      &:hover {
        border-width: 2px;
        box-shadow: 0px 0px 16px 0px #0075e3;
      }

      .newbie-guide-item-top {
        font-size: 20px;
        color: #0075e3;
      }

      .newbie-guide-item-bottom {
        margin-top: 15px;
      }
    }

    .newbie-guide-item:last-child {
      margin-right: 0;
    }
  }

  .guide-container {
    ::v-deep {
      .el-tabs__item {
        text-align: left;
        max-width: 250px;
        display: block;
        height: auto;
        word-break: break-all;
        white-space: break-spaces;
        line-height: 20px;
        padding: 15px 15px 15px 0;
        font-size: 15px;
      }
    }
  }
}
</style>
